const FEEDBACKS: Record<string, any> = {
  replay: {
    type: 'replay',
    title: 'Você já assistiu esse vídeo, o que deseja fazer?',
    btn: 'Assistir novamente',
    width: '270px',
  },
  ended: {
    type: 'ended',
    title: 'Esse vídeo encerrou, parabéns por assistir. E agora, o que deseja fazer?',
    btn: 'Assistir novamente',
    width: '374px',
  },
  continue: {
    type: 'continue',
    title: 'Você não assistiu esse vídeo inteiro, mas está marcado como concluído. O que deseja fazer?',
    btn: 'Continuar assistindo',
    width: '360px',
  },
  error: {
    type: 'error',
    title: 'Poxa! Parece que essa aula está com algum problema. Enquanto verificamos, você pode continuar aqui e assistir outra aula. Se ainda assim não funcionar, vai em relatar problema ao final dessa tela, e conta essa situação pra gente.',
    btn: 'Tentar novamente',
    width: '472px',
  },
};

export default FEEDBACKS;
